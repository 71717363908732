// **************************************** =========== Start Banner Scss  ===========  ****************************************//
.rts-banner-area{
    position: relative;
    .banner-inner{
        position: relative;
        .banner-single{
            background: url(../images/banner/banner-bg1.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 980px;
            padding: 350px 0 280px 0;
            position: relative;
            z-index: 1;
            @media(max-width:1600px) and (min-width:1366px){
                padding: 200px 0 0 0;
                height: 100vh;
            }
            @media(max-width:1024px){
                padding: 200px 0 200px;
                height: auto;
            }
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.40);
                z-index: -1;
            }
            &.banner-single-2{
                background: url(../images/banner/banner-bg2.jpg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            &.banner-single-4{
                background: url(../images/banner/banner-bg4.jpg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            &.banner-single-5{
                background: url(../images/banner/banner-bg5.jpg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            &.banner-single-6{
                background: url(../images/banner/banner-bg6.jpg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        .banner-content{
            opacity: 0;
            .sub-title{
                color: var(--color-white);
                transition-delay: 1s;
                display: block;
                animation: none;
                opacity: 0;
                margin-bottom: 30px;
            }
            .heading-title{
                color: var(--color-white);
                max-width: 60%;
                font-size: 80px;
                line-height: 90px;
                opacity: 0;
                transition-delay: 1.5s;
                display: block;
                animation: none;
                @media(max-width:1200px){
                    max-width: 70%;
                }
                @media(max-width:991px){
                    font-size: 60px;
                    line-height: 74px;
                }
                @media(max-width:768px){
                    max-width: 85%;
                }
                @media(max-width:576px){
                    max-width: 100%;
                    font-size: 50px;
                    line-height: 64px;
                }
                @media(max-width:450px){
                    font-size: 34px;
                    line-height: 50px;
                }
                a{
                    color: var(--color-white);
                    background-image: linear-gradient(to right, var(--color-white) 50%, transparent 50%);
                }
            }
            .banner-btn-area{
                transition-delay: 2.2s;
                display: block;
                animation: none;
                opacity: 0;
            }
            .banner-btn{
                border: 1px solid var(--color-white);
                display: block;
                width: max-content;
                padding: 14px 53px 13px;
                color: var(--color-white);
                color: #FFF;
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
                text-transform: capitalize;
                transition: all .4s;
                @media(max-width:576px){
                    padding: 10px 30px 8px;
                }
                &:hover{
                    color: var(--color-title);
                    background: var(--color-white);
                }
            }
        }
        .swiper-pagination{
            display: block;
            width: max-content;
            height: max-content;
            position: absolute;
            right: 50px;
            left: unset;
            top: 50%;
            transform: translateY(-50%);
            @media(max-width:768px){
                display: none;
            }
            .swiper-pagination-bullet{
                display: block;
                margin: 25px 0;
                background: var(--color-white);
                opacity: 1;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    border: 1px solid var(--color-white);
                    border-radius: 50%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                }
                &.swiper-pagination-bullet-active{
                    background: var(--color-primary);
                    &::after{
                        border: 1px solid var(--color-primary);
                    }
                }
            }
        }
        .swiper-wrapper{
            .swiper-slide.swiper-slide-active{
                .banner-content{
                    opacity: 1;
                    @media(max-width:1366px){
                        padding: 0 15px;
                    }
                    .sub-title{
                        animation: slideInUp3 .8s;
                        animation-delay: 1s;
                        display: block;
                        opacity: 1;
                    }
                    .heading-title{
                        animation: fadeInUp .8s ;
                        animation-delay: 1.5s;
                        opacity: 1;
                    }
                    .banner-btn-area{
                        animation: fadeInUp .5s ;
                        animation-delay: 2.2s;
                        opacity: 1;
                    }
                }
            }
        }
    }
    &.home-2{
        .banner-inner{
            .swiper-pagination-area{
                position: relative;
                .pagination-inner{
                    position: absolute;
                    left: 0;
                    bottom: 75px;
                    width: 140px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                    gap: 12px;
                    @media(max-width:1366px){
                        left: 15px;
                    }
                    .swiper-pagination{
                        display: block;
                        width: 70px;
                        height: 3px;
                        position: relative;
                        right: unset;
                        left: unset;
                        top: unset;
                        transform: none;
                        background: #BFBFBF;
                        .swiper-pagination-progressbar-fill{
                            background: var(--color-white);
                        }
                    }
                    .slide-range{
                        font-size: 16px;
                        color: var(--color-white);
                        font-family: var(--body-font);
                        margin-bottom: 0;
                    }
                }
            }  
            .banner-shape-area{
                position: absolute;
                right: 150px;
                bottom: 85px;
                z-index: 1;
                @media(max-width:768px){
                    display: none;
                }
                .round-img{
                    animation: rotateIt2 25s linear infinite;
                }
                .arrow{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

            }
            .banner-content{
                .heading-title{
                    max-width: 70%;
                    @media(max-width:991px){
                        font-size: 58px;
                        line-height: 70px;
                    }
                    @media(max-width:768px){
                        max-width: 85%;
                        font-size: 56px;
                    }
                    @media(max-width:768px){
                        max-width: 90%;
                    }
                    @media(max-width:576px){
                        font-size: 50px;
                        max-width: 100%;
                    }
                    @media(max-width:450px){
                        font-size: 32px;
                        line-height: 50px;
                    }
                }
            }
        }
    }
    &.home-3{
        .banner-inner{
            display: flex;
            position: relative;
            @media #{$smlg-device} {
                flex-direction: column-reverse;
            }
            .left-side-content{
                width: 50%;
                padding: 175px 120px 0 200px;
                background: url(../images/banner/bg-overlay.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                @media #{$extra-device} {
                    padding: 155px 120px 0 100px;
                }
                @media #{$laptop-device} {
                    padding: 50px 100px 0 50px;
                }
                @media #{$smlg-device} {
                    padding: 50px 100px 120px 50px;
                    width: 100%;
                }
                @media #{$sm-layout} {
                    padding: 50px;
                }
                @media #{$large-mobile} {
                    padding: 50px 15px;
                }
                .content-inner{
                    .title{
                        color: var(--color-title);
                        font-size: 90px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 94px;
                        text-transform: capitalize;
                        margin-bottom: 50px;
                        @media #{$extra-device} {
                            font-size: 80px;
                            line-height: 85px;
                        }
                        @media(max-width:1599px) and (min-width:1440px){
                            font-size: 70px;
                            line-height: 80px;
                            margin-bottom: 25px; 
                            img{
                                max-width: 40%;
                            }  
                        }
                        @media(max-width:1439px) and (min-width:1200px){
                            font-size: 65px;
                            line-height: 80px;
                            margin-bottom: 25px; 
                            img{
                                max-width: 50%;
                            }  
                        }
                        @media #{$smlg-device} {
                            font-size: 52px;
                            line-height: 65px;   
                            margin-bottom: 25px;  
                            max-width: 60%; 
                            img{
                                max-width: 30%;
                            }                  
                        }
                        @media(max-width:991px){
                            max-width: 82%;
                        }
                        @media(max-width:768px){
                            max-width: 100%;
                            img{
                                max-width: 40%;
                            }
                        }
                        @media #{$large-mobile} {
                            font-size: 30px;
                            line-height: 35px;   
                            margin-bottom: 25px;   
                            img{
                                display: none;
                            }                  
                        }
                    }
                    p.desc{
                        color: #262626;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        max-width: 80%;
                        @media #{$laptop-device} {
                            max-width: 100%;
                            margin-bottom: 20px;
                        }
                        @media #{$smlg-device} {
                            max-width: 80%;
                        }
                        @media #{$sm-layout} {
                            max-width: 100%;
                        }
                    }
                    .content-bottom{
                        display: flex;
                        align-items: center;
                        gap: 60px;
                        @media #{$laptop-device} {
                            gap: 25px;
                        }
                        @media #{$sm-layout} {
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 30px;
                        }
                        .rts-btn{
                            background: none;
                            border: 1px solid var(--color-title);
                            color: var(--color-title);
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 1;
                            padding: 18px 53px;
                            @media #{$laptop-device} {
                                padding: 18px 28px;
                            }
                        }
                        .freediver-social-media{
                            display: flex;
                            align-items: center;
                            .tag{
                                color: var(--color-title);
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 26px;
                                text-transform: capitalize;
                                margin-bottom: 0;
                                padding-right: 30px;
                                position: relative;
                                &::after{
                                    content: '';
                                    position: absolute;
                                    width: 16px;
                                    height: 1px;
                                    background: #D9D9D9;
                                    top: 50%;
                                    right: 0;
                                    transform: translateY(-50%);
                                }
                            }
                            a{
                                font-size: 14px;
                                text-align: center;
                                margin: 0 12px;
                                transition: all 0.3s ease;
                                position: relative;
                                color: #6F7073;
                                &:nth-child(1){
                                    margin-left: 0;
                                }
                                &:hover{
                                    color: var(--color-primary);
                                }
                            }
                        }
                    }
                }
            }
            .right-side-hero{
                width: 50%;
                @media #{$smlg-device} {
                    width: 100%;
                }
               .hero-image{
                background: var(--color-white);
                img{
                    width: 100%;
                }
               } 
            }
            .swiper-btn{
                position: absolute;
                width: 70px;
                height: 10px;
                bottom: 60px;
                right: 60px;
                @media(max-width:1440px) and (min-width:1200px){
                    bottom: 40px;
                }
                @media #{$small-mobile} {
                    bottom: 19px;
                    right: 36px;
                }
                .button{
                    display: block;
                    top: 50%;
                    transform: translateY(-50%);
                    height: auto;
                    width: auto;
                    margin: 0;
                    color: #262626;
                    transition: all .3s;
                    &:hover{
                        color: var(--color-primary);
                    }
                    &::after{
                        display: none;
                    }
                    &.swiper-button-prev{
                        left: 0;
                        right: unset;
                    }
                    &.swiper-button-next{
                        right: 0;
                        left: unset;
                    }
                }
            }
            .swiper-pagination-area{
                position: absolute;
                left: 20%;
                bottom: 40px;
                width: 140px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                gap: 12px;
                @media #{$laptop-device} {
                    left: 7%;
                }
                @media(max-width:1440px) and (min-width:1200px){
                    bottom: 20px;
                }
                @media #{$smlg-device} {
                    left: 5%;
                }
                @media #{$sm-layout} {
                    display: none;
                }
                .swiper-pagination{
                    display: block;
                    width: 70px;
                    height: 3px;
                    position: relative;
                    right: unset;
                    left: unset;
                    top: unset;
                    transform: none;
                    background: #BFBFBF;
                    .swiper-pagination-progressbar-fill{
                        background: var(--color-primary);
                    }
                }
                .slide-range{
                    font-size: 16px;
                    color: var(--color-title);
                    font-family: var(--body-font);
                    margin-bottom: 0;
                }
            } 
        }
    }
}
.rts-reservation-area{
    .reservation-inner{
        background: url(../images/banner/reservation-bg.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        position: relative;
        z-index: 1;
        margin-top: -100px;
        @media(max-width:1366px){
            margin: 0 15px;
            margin-top: -100px;
        }
    }
    form.appoinment-form{
        padding: 53px 60px;
        display: flex;
        align-items: end;
        gap: 30px;
        justify-content: center;
        @media(max-width:991px){
            flex-wrap: wrap;
        }
        @media(max-width:500px){
            padding: 45px 30px;
        }
        .single-input{
            display: block;
            width: 25%;
            @media(max-width:991px){
                width: 47%;
            }
            @media(max-width:768px){
                width: 100%;
            }
            &.phone{
                margin-top: 25px;
            }
            &.file{
                width: 100%;
                input{
                    height: unset;
                    padding: 20px;
                }
            }
            label{
                display: block;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                line-height: 14px;
                margin: 0px 0px 20px 0px;
                .indicator{
                    color: #f00;
                }
            }
            input{
                height: 50px;
                background: none;
                display: block;
                padding: 0 30px;
                border: 1px solid #EAEAEA4a;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
                &::placeholder{
                    color: #ADADAD !important;
                }
            }
            button{
                height: 50px;
                width: 100%;
                max-width: 100%;
                display: block;
                background: var(--color-primary);
                font-family: var(--body-font);
                font-weight: 500;
                @media(max-width:1200px) and (min-width:991px){
                    padding: 18px 30px;
                }
                @media(max-width:500px){
                    padding: 18px 30px;
                }
                &:hover{
                    background: #00bbdbe0;
                }
            }
        }
        .form-control{
            background: var(--color-primary);
            border: none;
            height: 120px;
            padding: 20px;
            font-size: 16px;
            &:focus{
                border: none;
                box-shadow: none;
            }
        }
    }
}
.rts-brand-area{
    .section-inner{
        .brand-image{
            text-align: center;
        }
    }
}

.rts-feature-area{
    .feature-inner{
        .feature-wrapper{
            position: relative;
            border-radius: 6px;
            overflow: hidden;
            box-shadow: 0px 4px 20px 0px rgba(40, 40, 40, 0.06);
            z-index: 1;
            transition: all .6s;
            &:hover{
                &::after{
                    transform: translateY(0);
                }
                &::before{
                    transform: translateY(-100%);
                }
                .bottom-content{
                    .icon{
                        svg{
                            path{
                                fill: var(--color-white);
                            }
                        }
                    }
                    .title{
                        color: var(--color-white);
                    }
                    p.desc{
                        color: #BFBFBF;
                    }
                    .feature-btn{
                        color: var(--color-white);
                    }
                }
            }
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 6px;
                background: rgba(17, 17, 17, 0.60);
                z-index: 0;
                transform: translateY(101%);
                transition: all .6s;
            }
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 6px;
                background: var(--color-white);
                z-index: 0;
                transition: all .6s;
                transform: translateY(0);
            }
            .image-area{
                border-radius: 6px;
                img{
                    border-radius: 7px;
                }
            }
            .bottom-content{
                position: absolute;
                bottom: 40px;
                text-align: center;
                z-index: 1;
                @media(max-width:576px){
                    width: 100%;
                }
                .icon{
                    margin-bottom: 40px;
                    transition: all .6s;
                    @media #{$smlg-device} {
                        margin-bottom: 13px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 40px;
                    }
                    @media #{$sm-layout} {
                        margin-bottom: 40px;
                    }
                    svg{
                        path{
                            transition: all .6s;
                        }
                    }
                }
                .title{
                    color: var(--color-title);
                    text-align: center;
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 32px;
                    text-transform: capitalize;
                    transition: all .6s;
                }
                p.desc{
                    color: #707070;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    text-transform: capitalize;
                    margin: auto;
                    margin-bottom: 20px;
                    max-width: 90%;
                    transition: all .6s;
                }
                .feature-btn{
                    color: var(--color-title);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 32px;
                    text-transform: capitalize;
                    transition: all .6s;
                }
            }
        }
    }
    &.home-3{
        .rts-section-title{
            max-width: 55%;
            @media #{$sm-layout} {
                max-width: 100%;
            }
            .heading-title{
                margin-bottom: 40px;
            }
        }
        .section-inner{
            .left-side-content{
                .desc{
                    max-width: 90%;
                    @media #{$large-mobile} {
                        max-width: 100%;
                    }
                }
            }
            .right-side-inner{
                .top-image{
                    margin-bottom: 40px;
                }
                ul.listed-image-area{
                    padding: 0;
                    margin: 0;
                    li.list-wrapper{
                        list-style: none;
                        position: relative;
                        overflow: hidden;
                        border-radius: 6px;
                        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
                        transition: all .6s;
                        margin-bottom: 20px;
                        @media #{$large-mobile} {
                            height: 150px;
                        }
                        @media #{$small-mobile} {
                            height: 250px;
                        }
                        &:hover{
                            &::after{
                                transform: translateX(0);
                            }
                            &::before{
                                transform: translateX(-100%);
                            }
                            .content-wrapper{
                                .content{
                                    .icon{
                                        svg{
                                            path{
                                                fill: var(--color-white);
                                            }
                                        }
                                    }
                                    .title{
                                        color: var(--color-white);
                                    }
                                }
                                .number{
                                    span{
                                        color: var(--color-white);
                                        -webkit-text-stroke-color: var(--color-white);
                                    }
                                }
                            }
                        }
                        &::after{
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            border-radius: 6px;
                            background: rgba(17, 17, 17, 0.60);
                            z-index: 0;
                            transition: all .6s;
                            transform: translateX(100%)
                        }
                        &::before{
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            border-radius: 6px;
                            background: var(--color-white);
                            z-index: 0;
                            transition: all .6s;
                        }

                        .content-wrapper{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 27px 30px;
                            z-index: 1;
                            transition: all .6s;
                            @media #{$small-mobile} {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                            .content{
                                display: flex;
                                align-items: center;
                                gap: 25px;
                                width: 50%;
                                transition: all .6s;
                                @media #{$large-mobile} {
                                    width: 100%;
                                }
                                .icon{
                                    svg{
                                        path{
                                            fill: var(--color-primary);
                                            transition: all .6s;
                                        }
                                    }
                                }
                                .title{
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: var(--color-title);
                                    margin-bottom: 0;
                                    transition: all .6s;
                                    @media #{$large-mobile} {
                                        font-size: 16px;
                                    }
                                }
                            }
                            .number{
                                width: 50%;
                                span{
                                    font-size: 64px;
                                    font-weight: 800;
                                    line-height: 34px;
                                    text-transform: capitalize;
                                    color: rgba(156, 156, 156, 1);
                                    -webkit-text-fill-color: transparent;
                                    -webkit-text-stroke-width: 1px;
                                    -webkit-text-stroke-color: rgba(156, 156, 156, 1);
                                    transition: all .6s;
                                }
                            }
                        }
                        &:nth-child(odd){
                            .content-wrapper{
                                .number{
                                    text-align: right;
                                    @media #{$small-mobile} {
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.rts-marquee-area{
    .section-inner{
        .top-inner,
        .bottom-inner{
            display: inline-block;
            ul.marquee{
                padding: 0;
                margin: 0;
                will-change: transform;
                animation: marquee 65s linear infinite;
                align-items: center;
                display: flex;
                padding: 5px 0;
                li{
                    list-style: none;
                    flex: 0 0 auto;
                    position: relative;
                    text-align: center;
                    margin: 0 20px;
                    h4{
                        margin-bottom: 0;
                        color: var(--color-title);
                        text-align: center;
                        font-size: 36px;
                        font-weight: 400;
                        line-height: 70px;
                        text-transform: capitalize;
                    }
                }
            }
        }
        .bottom-inner{
            background: #262626;
            ul.marquee{
                animation: marquee-2 65s linear infinite;
                li{
                    h4{
                        color: var(--color-white);
                    }
                    svg{
                        path{
                            fill: var(--color-white);
                        }
                    }
                }
            }
        }
    }
}
.rts-playing-process-area{
    .rts-section-title{
        .heading-title{
            margin-bottom: 70px;
        }
    }
    .section-inner{
        .playing-process-inner{
            .inner-separator{
                position: relative;
                &::after{
                    position: absolute;
                    content: "";
                    left: 50%;
                    bottom: 0;
                    width: 98%;
                    height: 1px;
                    background: #D9D9D9;
                    transform: translateX(-50%);
                    @media #{$md-layout} {
                        display: none;
                    }
                    @media #{$sm-layout} {
                        display: none;
                    }
                }
            }
            .item-wrapper{
                position: relative;
                &:hover{
                    &::before{
                        background: var(--color-primary);
                    }
                    .icon{
                        svg{
                            path{
                                fill: var(--color-primary);
                            }
                        }
                    }
                    .title{
                        color: var(--color-primary);
                    }
                    .book-now{
                        color: var(--color-primary);
                    }
                }
                &.active{
                    &::before{
                        background: var(--color-primary);
                    }
                    .icon{
                        svg{
                            path{
                                fill: var(--color-primary);
                            }
                        }
                    }
                    .title{
                        color: var(--color-primary);
                    }
                    .book-now{
                        color: var(--color-primary);
                    }
                }
                &::after{
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: -53px;
                    width: 8px;
                    height: 8px;
                    background: var(--color-white);
                    border-radius: 50%;
                    transition: 0.3s;
                    z-index: 2;
                    
                }
                &::before{
                    position: absolute;
                    content: "";
                    left: -4px;
                    bottom: -57px;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: #D2D2D2;
                    transition: 0.3s;
                    z-index: 2;
                    transition: all .3s;
                    &:hover{
                        background: var(--color-primary);
                    }
                }
                .icon{
                    margin-bottom: 30px;
                    transition: all .3s;
                    svg{
                        path{
                            fill: #000;
                            transition: all .3s;
                        }
                    }
                }
                .title{
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 36px;
                    text-transform: capitalize;
                    transition: all .3s;
                }
                .desc{
                    color: #707070;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    max-width: 90%;
                    margin-bottom: 20px;
                    transition: all .3s;
                }
                .book-now{
                    color: var(--color-title);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 32px;
                    text-transform: capitalize;
                    transition: all .3s;
                }
            }
        }
    }
}