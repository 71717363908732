// **************************************** =========== Start Footer Scss ===========  ****************************************//
.freediver-footer-area{
    padding-top: 90px;
    background-color: var(--color-title);
    position: relative;
    @media(max-width:1200px){
        padding: 90px 20px 0 20px;
    }
    @media(max-width:576px){
        padding: 60px 15px 0 15px;
    }
    .freediver-get-in-touch{
        .footer-logo{
            margin-bottom: 40px;
        }
        h4{
            color: var(--color-white);
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 30px;
        }
        .desc{
            color: #6F7073;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
    }
    .freediver-row{
        display: flex;
        padding-bottom: 100px;
        flex-wrap: wrap;
        @media #{$smlg-device} {
            gap: 30px;
        }
        @media #{$large-mobile} {
            gap: 15px;
        }
        .freediver-footer-content-1{
            width: 36%;
            @media(max-width:991px){
                width: 50%;
                margin-bottom: 50px;
            }
            @media(max-width:576px){
                width: 100%;
            }
            .freediver-footer-address{
                p.desc{
                    color: #6F7073;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    max-width: 65%;
                    margin-bottom: 20px;
                    @media(max-width:768px){
                        max-width: 80%;
                    }
                }
                span{
                    display: block;
                    margin-bottom: 25px;
                    color: rgba(255, 255, 255, 0.8);
                    i{
                        margin-right: 15px;
                    }
                }
                .freediver-footer-social-media{
                    display: flex;
                    align-items: center;
                    .tag{
                        color: var(--color-white);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 26px;
                        text-transform: capitalize;
                        margin-bottom: 0;
                        padding-right: 30px;
                        position: relative;
                        &::after{
                            content: '';
                            position: absolute;
                            width: 16px;
                            height: 1px;
                            background: var(--color-white);
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);

                        }
                    }
                    a{
                        font-size: 16px;
                        border-radius: 50%;
                        text-align: center;
                        margin: 0 12px;
                        transition: all 0.3s ease;
                        position: relative;
                        color: var(--color-white);
                        &:nth-child(1){
                            margin-left: 0;
                        }
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
            }

        }
        .freediver-footer-content-2{
            width: 19%;
            @media(max-width:991px){
                width: 50%;
                margin-bottom: 50px;
            }
            @media(max-width:576px){
                width: 100%;
            }
            .freediver-footer-most-popular{
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        width: 38%;
                        transition: all ease 0.3s;
                        margin-bottom: 12px;
                        &:hover a{
                            color: var(--color-primary);
                        }
                        a{
                            color: #6F7073;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px;
                            transition: all ease 0.3s;
                        }
                        &:nth-child(1){
                            margin-top: 0;
                        }
                        &:nth-child(2){
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .freediver-footer-content-3{
            width: 20%;
            @media(max-width:991px){
                width: 50%;
            }
            @media(max-width:576px){
                width: 100%;
                margin-bottom: 50px;
            }
            @media #{$small-mobile} {
                margin-bottom: 0;
            }
            .freediver-footer-help{
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        margin-bottom: 12px;
                        transition: all ease 0.3s;
                        &:hover a{
                            color: var(--color-primary);
                        }
                        a{
                            color: #6F7073;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px;
                            transition: all ease 0.3s;
                        }
                        &:nth-child(1){
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .freediver-footer-content-4{
            width: 25%;
            @media(max-width:991px){
                width: 50%;
            }
            @media(max-width:576px){
                width: 100%;
            }
           .freediver-footer-news-text{
                margin-bottom: 18px;
                max-width: 95%;
                p{
                    color: #6F7073;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    margin-bottom: 30px;
                }
           }
           .freediver-subscribe-box-button{
                form{
                    position: relative;
                    .freediver-subscribe-input-fill{
                        position: relative;
                        input{
                            border: 1px solid rgba(255, 255, 255, 0.6);
                            background-color: var(--color-white);
                            padding: 12px 0 12px 20px;
                            &::placeholder{
                                color: #fff;
                                font-size: 15px;
                            }
                        }
                        .check-box{
                            position: absolute;
                            bottom: -50px;
                            label{
                                color: #6F7073;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 26px;
                            }
                        }
                    }
                    .freediver-footer-area-subscribe-button{
                        width: max-content;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 10px;
                        margin: 0;
                        display: block;
                        button{
                            background-color: var(--color-primary);
                            padding: 9px 13px 6px;
                            text-align: center;
                            color: var(--color-white);
                            font-size: 16px;
                            position: relative;
                            border: 1px solid transparent;
                        }
                    }
                }
           } 
        }
    }
    .freediver-footer-copyright-area{
        padding: 30px 0 20px;
        border-top: 1px solid #181818;
        .copyright-area-inner{
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media(max-width:576px){
                display: block;
                text-align: center;
            }
            .copyright-content{
                @media(max-width:576px){
                    margin: 20px 0;
                }
                .title{
                    color: var(--color-white);
                    font-family: var(--body-font);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    a{
                        color: var(--color-primary);
                    }
                }
            }
            .copyright-menu{
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 30px;
                @media(max-width:576px){
                    justify-content: center;
                }
                li{
                    list-style: none;
                    a{
                        color: var(--color-white);
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        text-transform: capitalize;
                        transition: all .3s;
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
    &.footer-two{
        &::after{
            position: absolute;
            content: '';
            width: 1px;
            height: 90%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background: #181818;
        }
        .freediver-row{
            @media #{$smlg-device} {
                gap: 30px;
            }
            @media #{$large-mobile} {
                gap: 15px;
                padding-bottom: 50px;
            }
            .freediver-footer-content-1{
                flex-basis: 60%;
                @media #{$smlg-device} {
                    flex-basis: 100%;
                }
                .freediver-get-in-touch{
                    h2{
                        font-size: 48px;
                        line-height: 58px;
                        font-weight: 400;
                        color: var(--color-white);
                        margin-bottom: 30px;
                        @media #{$large-mobile} {
                            font-size: 28px;
                            line-height: 15px;
                        }
                    }
                }
                .freediver-footer-news-text{
                    margin-bottom: 40px;
                    p{
                        font-size: 15px;
                    }
                }
                .freediver-subscribe-box-button{
                    max-width: 410px;
                    position: relative;
                    .freediver-subscribe-input-fill{
                        input{
                            padding: 10px 0;
                            border-bottom: 1px solid rgba(38, 38, 38, 1);
                            &:focus{
                                border-width: 0 0 1px !important;
                                border-color: var(--color-primary) !important;
                                border-style: solid;
                            }
                        }
                    }
                    .freediver-footer-area-subscribe-button{
                        width: max-content;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        background: var(--color-primary);
                        button{
                            color: var(--color-white);
                            padding: 6px 10px;
                        }
                    }
                }
            }
            .freediver-footer-content-2{
                flex-basis: 15%;
                @media #{$smlg-device} {
                    flex-basis: 45%;
                }
                @media #{$sm-layout} {
                    flex-basis: 100%;
                }
            }
            .freediver-footer-content-3{
                flex-basis: 25%;
                @media #{$smlg-device} {
                    flex-basis: 45%;
                }
                @media #{$sm-layout} {
                    flex-basis: 100%;
                }
                .freediver-footer-address{
                    .phone{
                        color: #6F7073;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                        transition: all .3s;
                        display: block;
                        margin-bottom: 10px;
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                    .desc{
                        max-width: 75%;
                        margin-bottom: 20px;
                    }
                }
                .freediver-footer-social-media{
                    display: flex;
                    align-items: center;
                    .tag{
                        color: var(--color-white);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 26px;
                        text-transform: capitalize;
                        margin-bottom: 0;
                        padding-right: 30px;
                        position: relative;
                        &::after{
                            content: '';
                            position: absolute;
                            width: 16px;
                            height: 1px;
                            background: var(--color-white);
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);

                        }
                    }
                    a{
                        font-size: 16px;
                        border-radius: 50%;
                        text-align: center;
                        margin: 0 12px;
                        transition: all 0.3s ease;
                        position: relative;
                        color: var(--color-white);
                        &:nth-child(1){
                            margin-left: 0;
                        }
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}
.scroll-top-btn {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 150;
    background: var(--color-primary);
    color: #ffffff;
    border-radius: 6px;
    display: none;
    border: none;
    i{
        font-size: 22px;
    }
}