// **************************************** =========== Start Header Scss  ===========  ****************************************//
.freediver-header-area{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    width: 100%;
    .freediver-home-menu{
        .freediver-home-menu-inner{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 100px;
            @media(max-width:1366px){
                padding: 20px 25px;
            }
            @media(max-width:450px){
                padding: 20px;
            }
            .freediver-logo-area{
                flex-basis: 15%;
                @media(max-width:991px){
                    flex-basis: auto;
                }
            }
            .nav-area{
                flex-basis: 70%;
                @media(max-width:991px){
                    display: none;
                }
                .freediver-menu{
                    .freediver-desktop-menu{
                        display: flex;
                        justify-content: center;
                        -webkit-box-orient: horizontal;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: row;
                        flex-direction: row;
                        max-height: 100%;
                        overflow: visible;
                        margin: 0;
                        .menu-item{
                            margin: 0;
                            color: var(--color-white);
                            font-family: var(--body-font);
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 26px;
                            text-transform: capitalize;
                            padding: 0 30px;
                            cursor: pointer;
                            @media(max-width:1200px){
                                padding: 0 20px;
                            }
                            .freediver-dropdown-main-element,
                            .main-element{
                                padding: 40px 0;
                                position: relative;
                                &::after{
                                    content: '';
                                    position: absolute;
                                    width: 0%;
                                    height: 2px;
                                    left: 0;
                                    bottom: 35px;
                                    background: var(--color-white);
                                    transition: all .3s ease-in-out ;
                                }
                            }

                            &:hover{
                                .freediver-dropdown-main-element,
                                .main-element{
                                    color: var(--color-white);
                                    &::after{
                                        width: 100%;
                                    }
                                }
                            }
                            &.active{
                                .freediver-dropdown-main-element{
                                    color: var(--color-white);
                                    &::after{
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        .freediver-has-dropdown{
                            &:hover{
                                .freediver-dropdown-main-element{
                                    color: var(--color-white);
                                    &::after{
                                        width: 100%;
                                    }
                                }
                                .freediver-submenu{
                                    visibility: visible;
                                    opacity: 1;
                                    transform: translateY(37px);
                                }
                            }
                        }
                        .freediver-submenu{
                            display: flex;
                            -webkit-box-orient: vertical;
                            -webkit-box-direction: normal;
                            -ms-flex-direction: column;
                            flex-direction: column;
                            position: absolute;
                            opacity: 0;
                            width: 250px;
                            visibility: hidden;
                            overflow: visible;
                            transform: translateY(47px);
                            transition: all .3s ease-in-out;
                            padding: 15px 0;
                            background: var(--color-title);
                            -webkit-box-shadow: 9px 9px 22px -1px rgba(0, 0, 0, 0.12);
                            box-shadow: 9px 9px 22px -1px rgba(0, 0, 0, 0.12);
                            .nav-item{
                                margin: 0;
                                transition: all .3s ease-in-out;
                                position: relative;
                                &:hover{
                                    a.nav-link{
                                        padding-left: 40px;
                                        color: var(--color-white);
                                        &::after{
                                            width: 12px;
                                        }
                                    }
                                    
                                }
                                a.nav-link{
                                    display: block;
                                    text-transform: capitalize;
                                    color: #fff9;
                                    text-decoration: none;
                                    padding: 10px 20px;
                                    padding-right: 80px;
                                    -webkit-transition: all .3s ease-in-out;
                                    transition: all .3s ease-in-out;
                                    position: relative;
                                    font-weight: 400;
                                    font-size: 15px;
                                    &::after{
                                        content: '';
                                        position: absolute;
                                        width: 0;
                                        height: 2px;
                                        top: 50%;
                                        left: 20px;
                                        transform: translateY(-50%);
                                        background: var(--color-white);
                                        transition: all .3s ease-in-out;
                                    }
                                    &.active{
                                        padding-left: 40px;
                                        color: var(--color-white);
                                        &::after{
                                            width: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }  
            }
            .freediver-menu-btn{
                flex-basis: 15%;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: end;
                gap: 40px;
                @media(max-width:991px){
                    flex-basis: auto;
                }
                .freediver-cart-area{
                    position: relative;
                    cursor: pointer;
                    @media(max-width:576px){
                        display: none;
                    }
                    svg{
                        path{
                            fill: var(--color-white);
                        }
                    }
                    .tag{
                        position: absolute;
                        top: -6px;
                        right: -6px;
                        width: 16px;
                        height: 16px;
                        svg{
                            display: block;
                        }
                        &::after{
                            content: '3';
                            position: absolute;
                            top: 50%;
                            left: 52%;
                            transform: translate(-52%, -50%);
                            font-size: 14px;
                            color: var(--color-white);
                        }
                    }
                }
                .freediver-search-area{
                    @media(max-width:576px){
                        display: none;
                    }
                    .freediver-search-btn{
                        svg{
                            path{
                                stroke: var(--color-white);
                                stroke-width: 2px;
                            }
                        }
                    }
                }
                .freediver-header-menu-bar{
                    svg{
                        rect{
                            fill: var(--color-white);
                        }
                    }
                }
            }
        }
    }
    &.header-2{
        .freediver-home-menu{
            .freediver-home-menu-inner{
                @media(max-width:1600px){
                    padding: 20px 50px;
                }
                @media(max-width:1200px){
                    padding: 20px 25px;
                }
                .nav-area{
                    flex-basis: 40%;
                    width: 40%;
                    @media(max-width:1366px){
                        flex-basis: 50%;
                    }
                    .freediver-menu{
                        .freediver-desktop-menu{
                            justify-content: left;
                            .menu-item{
                                @media(max-width:1600px){
                                    padding: 0 20px;
                                }
                                @media(max-width:1200px){
                                    padding: 0 15px;
                                }
                            }
                        }
                    } 
                }
                .freediver-logo-area{
                    flex-basis: 20%;
                    width: 20%;
                    text-align: center;
                    @media(max-width:991px){
                        flex-basis: 50%;
                        width: 50%;
                        text-align: left;
                    }
                }
                .freediver-menu-btn{
                    flex-basis: 40%;
                    width: 40%;
                    @media(max-width:1366px){
                        flex-basis: 30%;
                    }
                    @media(max-width:991px){
                        flex-basis: 50%;
                        width: 50%;
                    }
                }
            }
        }
    }
    &.header-3{
        position: relative;
        background: var(--color-white);
        .freediver-home-menu{
            .freediver-home-menu-inner{
                .nav-area{
                    .freediver-menu .freediver-desktop-menu .menu-item{
                        .freediver-dropdown-main-element,
                        .main-element{
                            color: var(--color-title);
                            &::after{
                                background: var(--color-title);
                            }
                        } 
                    }
                }
                .freediver-menu-btn{
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    gap: 40px;
                    .freediver-cart-area{
                        position: relative;
                        svg{
                            path{
                                fill: #6F7073;
                            }
                        }
                        .tag{
                            position: absolute;
                            top: -6px;
                            right: -6px;
                            width: 16px;
                            height: 16px;
                            svg{
                                display: block;
                            }
                            &::after{
                                content: '3';
                                position: absolute;
                                top: 50%;
                                left: 52%;
                                transform: translate(-52%, -50%);
                                font-size: 14px;
                                color: var(--color-white);
                            }
                        }
                    }
                    .freediver-search-area{
                       .freediver-search-btn{
                        svg{
                            path{
                                stroke: #6F7073;
                            }
                        }
                       } 
                    }
                    .freediver-header-menu-bar{
                        svg{
                            rect{
                                fill: #6F7073;
                            }
                        }
                    }
                }
            }
        }
        &.header-sticky{
            position: fixed;
        }
    }
}