:root {
    // themes color
    --color-primary: #00bbdb;  
    --title-font:'Rufina', serif;
    --body-font:'Roboto', sans-serif;



    // body color
    --color-body:#707070;
    --dark-color-body:rgba(255, 255, 255, .8);

    // After Before Title Color
    --color-title-af-bf: #F9F9F9;
    // title color
    --color-title: #111;
    --dark-color-heading-1: #ffffff;

    // bg dark color

    --bg-dark-one: #27272e;
    --bg-dark-two: #212121;
    --bg-dark-three: #0f0f1c;


    --color-white:#fff;

    // notify Colors
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;


    //Social icon colors
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;


    // Font weight
    --p-light: 300;
    --p-regular: 400;
    --p-medium: 500;
    --p-semi-bold: 600;
    --p-bold: 700;
    --p-extra-bold: 800;
    --p-black: 900;

    // Font weight
    --s-light: 300;
    --s-regular: 400;
    --s-medium: 500;
    --s-semi-bold: 600;
    --s-bold: 700;
    --s-extra-bold: 800;
    --s-black: 900;


    //transition easing
    --transition: 0.3s;


    // Font Family
    // --font-primary: 'Inter, sans-serif';
    // --font-secondary:'Bona Nova', serif;


    //Fonts Size
    --font-size-b1: 16px;
    --font-size-b2: 16px;
    --font-size-b3: 22px;


    //Line Height
    --line-height-b1: 26px;
    --line-height-b2: 26px;
    --line-height-b3: 1.7;


    // Heading Font
    --h1: 66px;
    --h2: 40px;
    --h3: 36px;
    --h4: 30px;
    --h5: 26px;
    --h6: 22px;

}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';