


body {
    scroll-behavior: auto;
    background: #fff;
    overflow-x: hidden;
}
.container{
    max-width: 1290px;
}

// It's every Post Title For Hover Effect
.title-hover{
    background-size: 200% 1px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--color-title)), color-stop(50%, transparent));
    background-image: linear-gradient(to right, var(--color-title) 50%, transparent 50%);
    -webkit-transition: all 500ms;
    transition: all 500ms;
    &:hover{
        background-position: 0% 100%;
        color: var(--color-title);
    }
}
p{
    font-size: 16px;
    line-height: 26px;
}
a.pe-none{
    font-size: 14px;
    font-weight: 500;
}
// It's for every Image Trasnform sacle effect
.img-transition-scale{
    overflow: hidden;
    &:hover{
        a{ 

            img{
                transform: scale(110%);
            }
        }
    }
    a{
        display: block;
        width: 100%;
        img{
            transform: scale(100%);
            transition: all 0.4s;
        }
    }
}
.home-three{
    .img-transition-scale{
        overflow: hidden;
        border-radius: 20px; 
        &:hover{
            a{
                img{
                    transform: scale(110%);
                }
            }
        }
        a{
            display: block;
            width: 100%;
            img{
                transform: scale(100%);
                transition: all 0.4s;
                width: 100%;
            }
        }
    } 
}
/*------------------------
    Header Sticky 
--------------------------*/
/* Start Sticky Menu */
.freediver-header-area.header-sticky {
    position: fixed;
    z-index: 99;
    transition: all 0.4s;
    background: var(--color-title);
  }
  .echo-header-area{
    .echo-header-top.remove-content{
        display: none;
    }
  }
  /* End Sticky Menu */



// Active Class

// custom column

.col-lg-20{
    width: 20%;
    float: left;
}

.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.col-lg-20{
    @media (min-width: 1200px) {
        width: 20%;
        float: left;
    }
    @media #{$smlg-device} {
        width: 33%;
        float: left;
    }
    @media #{$md-layout} {
        width: 50%;
        float: left;
    }
    @media #{$sm-layout} {
        width: 50%;
        float: left;
    }
    @media #{$large-mobile} {
        width: 50%;
        float: left;
    }
    @media #{$small-mobile} {
        width: 100%;
        float: left;
    }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5D666F !important;
    opacity: 1 !important; /* Firefox */
    font-size: 16px;
}
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5D666F  !important;
    font-size: 16px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #5D666F !important;
    font-size: 16px;
  }


.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}


// start hear

.bg-1{
    background: #e00a0a;
}
.bg-secondary{
    background: #1D2027!important;
}

.bg-white{
    background: #FFFFFF;
}

.bg-footer-one{
    background-repeat: no-repeat;
    background-size: cover;
    background: #0E1422;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::after{
        content: "";
        position: absolute;
        background-image: url(../images/footer/h1-shape/01.svg);
        height: 450px;
        width: 450px;
        right: -3%;
        background-repeat: no-repeat;
        bottom: -5%;
        @media #{$laptop-device} {   
            right: -15%;
        }
        @media #{$smlg-device} {   
            right: -15%;
        }
        @media #{$md-layout} {   
            left: 53%;
        }
    }
    &::before{
        content: "";
        position: absolute;
        background-image: url(../images/footer/h1-shape/02.png);
        height: 800px;
        width: 823px;
        left: -16%;
        background-position: center;
        bottom: -13%;
        background-size: cover;
        z-index: -1;
        background-repeat: no-repeat;
        @media #{$laptop-device} {
            left: -25%;
        }
        @media #{$smlg-device} {
            left: -25%;
        }
        @media #{$md-layout} {
            left: -48%;
        }
    }
    .bg-shape-f1{
        position: relative;
        z-index: 1;
        &::after{
            position: absolute;
            background-image: url(../images/footer/h1-shape/03.png);
            content: '';
            height: 500px;
            width: 523px;
            left: -25%;
            top: -20%;
            z-index: -1;
            background-repeat: no-repeat;
            @media #{$laptop-device} {
                left: -30%;
                top: -32%;
            }
            @media #{$smlg-device} {
                left: -30%;
                top: -32%;
            }
        }
    }
}


// title pre-title



.over_link{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    left: 0;
    top: 0;
}


.title-area{
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #3B38EB;
    }
    .title{
        margin-top: 10px;
        margin-bottom: 30px;
        @media #{$sm-layout} {
            margin-bottom: 15px;
        }
    }
}

.bg-light{
    background: rgba(236, 242, 246, 1);
}
.photographer-home.dark-version{
    .header--sticky.sticky{
        background: #2f2d24 !important;
    }
}


.header--sticky {
    transition: 0.3s;
    top: 0;
    z-index: 5;
    position: relative;
    &.sticky{
        position: fixed !important;
        top: 0;
        display: block;
        backdrop-filter: blur(9px);
        width: 100%;
        box-shadow: 0px 7px 18px #1810100d;
        background: #fff !important;
        z-index: 999;
    }
}


.bg_dark{
    background: #1C1C1C;
}


.g-6, .gy-6 {
    --bs-gutter-y: 4rem !important;
}
.g-6, .gx-6 {
    --bs-gutter-x: 4rem !important;
}
.g-24, .gy-24 {
    --bs-gutter-y: 2.4rem !important;
}
.g-24, .gx-24 {
    --bs-gutter-x: 2.4rem !important;
}


.h2-title-area{
    position: relative;
    z-index: 4;
    &::after{
        position: absolute;
        content: "";
        height: 99%;
        width: 100%;
        top: 4px;
        z-index: -1;
        left: 0;
        background: linear-gradient(180deg, #ffffff00 0%, #ecf0f3 100%);
    }
    span{
        &.bg-text{
            position: absolute;
            z-index: 1;
            font-weight: 800;
            font-size: 120px;
            z-index: -1;
            left: 0;
            top: 54px;
            color: #fff;
            text-transform: uppercase;
            max-width: max-content;
            width: max-content;
        }
    }
    .pre-title{
        display: inline-block;
        padding: 2px 15px;
        border: 1px solid #141414;
        border-radius: 33px;
        color: #141414;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }
    .title{
        margin-top: 30px;
        font-size: 48px;
        @media #{$large-mobile} {
            font-size: 27px;
        }
    }
}

.photographer-home.dark-version{
    .h2-title-area .pre-title{
        border: 1px solid #d3d3d3;
        border-radius: 33px;
        color: #d3d3d3;
    }
}

.h2-title-area.text-center{
    position: relative;
    z-index: 4;
    &::after{
        position: absolute;
        content: "";
        height: 71%;
        width: 100%;
        top: 0;
        z-index: -1;
        left: 0;
        background: linear-gradient(180deg, #ffffff00 0%, rgb(236 240 243) 100%);
    }
    span{
        &.bg-text{
            position: absolute;
            z-index: 1;
            font-weight: 800;
            font-size: 120px;
            z-index: -1;
            left: 50%;
            top: 15px;
            color: #fff;
            text-transform: uppercase;
            transform: translateX(-50%);
            max-width: max-content;
            width: max-content;
            @media #{$sm-layout} {
                font-size: 62px;
            }
        }
    }
    .pre-title{
        display: inline-block;
        padding: 2px 15px;
        border: 1px solid #141414;
        border-radius: 33px;
        color: #141414;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }
    .title{
        margin-top: 30px;
        font-size: 48px;
        @media #{$sm-layout} {
            font-size: 27px;
        }
    }
}

.title-area-h3{
    .pre-title{
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.1em;
        color: #74787C;
    }
    .title{
        font-weight: 600;
        font-size: 60px;
        line-height: 82px;
        color: #141414;
        @media #{$small-mobile} {
            line-height: 44px;
        }
        @media #{$sm-layout} {
            font-size: 36px;
        }
        span{
            color: #FF390E;
        }
    }
}



.title-area-home-4{
    .pre-title{
        background: linear-gradient(130.08deg, #FFFFFF 0%, #ECF0F3 100%);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 4px 40px #D3DAE1;
        border-radius: 100px;
        padding: 3px 24px;
        text-transform: uppercase;
        color: #141414;
        font-weight: 700;
    }
    .title{
        font-weight: 800;
        font-size: 48px;
        line-height: 66px;
        color: #141414;
        margin-top: 15px;
        @media #{$laptop-device} {
            font-size: 39px;
            line-height: 61px;
        }
        @media #{$md-layout} {
            font-size: 30px;
            line-height: 39px;
        }
        @media #{$sm-layout} {
            font-size: 26px;
            line-height: 42px;
        }
        @media #{$small-mobile} {
            font-size: 20px;
            line-height: 33px;
        }
    }
}
.rts-contact-area.four{
    @media #{$md-layout} {
        background: transparent;
    }
    @media #{$sm-layout} {
        background: transparent;
    }
}

.echo-breadcrumb-area{
    background-image: url('../images/breadcrumb/breadcrumb.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 0;
    .breadcrumb-inner{
        .meta{
            .next{
                text-transform: uppercase;
            }
        }
        .title{
            margin-bottom: 0;
            font-size: 56px;
            line-height: 76px;
        }
    }
}
.echo-breadcrumb-area-2{
    background-image: url('../images/breadcrumb/breadcrumb.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 65px 0;
    .echo-author-content{
        display: flex;
        gap: 30px;
        h5{
            margin-bottom: 15px;
            font-size: 25px;
            line-height: 35px;
        }
        p{
            margin-bottom: 15px;
        }
        .echo-footer-social-media{
            a:first-child{
                margin-left: -5px;
            }
            a{
                font-size: 16px;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                line-height: 35px;
                border: 1px solid #5E5E5E;
                text-align: center;
                display: inline-block;
                margin: 0 5px;
                transition: all 0.3s ease;
                transform: scale(0.9);
                position: relative;
                i{
                    color: #5E5E5E;
                }
                &:hover{
                    background-color: var(--color-primary);
                    border: 1px solid transparent;
                    i{
                        color: #fff;
                    }
                }
            }
        }
    }

}

/* Category 3 Style blog Shep */
.echo-ct-style-3-shep{
    display: inline-block;
    position: absolute;
    left: 50%;
    bottom: -45px;
    transform: translate(-50%);

    p{
        font-size: 14px;
        line-height: 22px;
        background-color: #000;
        color: #fff;
        padding: 8px 18px;
        text-align: center;
        text-transform: uppercase;
    }
}

.rts-dark-light{
    transition: all .4s;
    cursor: pointer;
    font-size: 20px;
    i{
        transition: all .4s;
    }
}
[data-theme='light']{
    .rts-dark-light{
        i.fa-sun{
            display: none;
        }
        i.fa-moon{
            display: block;
        }
    }
}
[data-theme='dark']{
    .rts-dark-light{
        i.fa-sun{
            display: block;
        }
        i.fa-moon{
            display: none;
        }
    }
}
.search-input-area{
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 200px 0;
    background: #fff;
    -webkit-box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.46);
    box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.46);
    z-index: 1100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    &.show{
        visibility: visible;
        -webkit-transform: inherit;
        transform: inherit;
        opacity: 1;
    }
    .search-input-inner{
        display: -webkit-box;
        display: -ms-flexbox;
        justify-content: center;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        .input-div{
            width: 80%;
            position: relative;
        }
        input{
            background: none;
            border: 1px solid rgba(94, 94, 94, 0.1);
            padding: 20px;
            padding-left: 40px;
            border-radius: 0;
            width: 100%;
            font-size: 15px;
            position: relative;
            &:focus{
                border: 1px solid var(--color-primary);
            }
        }
        .search-close-icon{
            width: 6%;
            i{
                color: #FFFFFF;
                font-size: 14px;
                cursor: pointer;
                background: var(--color-primary);
                width: 100%;
                height: 70px;
                line-height: 70px;
                text-align: center;
            }
        }
    }
}
.home-one{
    .rts-darkmode{
        position: relative;
    }
}
.home-two{
    .rts-darkmode{
        position: relative; 
    }
}
.home-three{
    .echo-site-main-logo-menu-social .echo-home-1-social-media-icons{
        text-align: right;
        display: flex;
        justify-content: end;
        align-items: center;
        
        .rts-darkmode{
            position: relative;
            margin-left: 40px;
            @media(max-width:1200px){
                margin-left: 30px;
            }
            @media(max-width:991px){
                margin-left: 0;
                margin-right: 40px;
            }
            @media(max-width:400px){
                margin-left: 0;
                margin-right: 30px;
            }
            &::before{
                content: "";
                position: absolute;
                width: 1px;
                height: 25px;
                top: 50%;
                transform: translateY(-50%);
                opacity: .6;
                left: -20px;
                background-color: #fff;
                @media(max-width:991px){
                    display: none;
                }
            }
            i{
                color: #FFFFFF;
            }
        }
        .echo-header-top-menu-bar{
            position: relative;
            &::before{
                content: "";
                position: absolute;
                width: 1px;
                height: 25px;
                top: 50%;
                transform: translateY(-50%);
                opacity: .6;
                left: -20px;
                background-color: #fff;
                @media(max-width:400px){
                    left: -15px;
                }
            }
            svg{
                path{
                    fill: #FFFFFF;
                }
            }
        }
    }
    .echo-header-area.header-sticky .echo-home-1-menu .echo-header-top-menu-bar{
        display: none;
    }
}
.home-four{
    .rts-darkmode{
        position: relative;
        i{
            color: #FFFFFF;
        }
    }
}
.innerpage{
    .echo-site-main-logo-menu-social .echo-home-1-social-media-icons{
        text-align: right;
        display: flex;
        justify-content: end;
        align-items: center;
        ul{
            margin-right: 0;
        }
        .rts-darkmode{
            position: relative;
            &::before{
                content: "";
                position: absolute;
                width: 1px;
                height: 25px;
                top: 50%;
                transform: translateY(-50%);
                opacity: .6;
                left: -20px;
                background-color: #181823;
            } 
            i{
                color: #181823;
            }
        }
    }
    .echo-header-top{
        padding: 10px 0;
        background: #181823;
        .echo-meta-jobs-text{
            span{
                color: #FFFFFF;
            }
        }
        .echo-day-name,
        .echo-date-time{
            color: #FFFFFF;
        }
        .echo-subscribe{
            position: relative;
            a{
                padding: 5px 0;
                border-width: 0 0 1px 0;
                border-style: solid;
                border-color: #FFFFFF;
                color: #FFFFFF;
                &:hover{
                    background: none;
                    border-width: 0 0 1px 0 !important;
                    border-color: #FFFFFF !important;
                }
                i{
                    margin-right: 5px;
                }
            }  
        }
        .rts-dark-light{
            i{
                color: #FFFFFF;
            }
        }
       .echo-header-top-search-btn::after{
        background-color: rgba(255, 255, 255, 0.8) !important;
       }
        .echo-header-top-menu-bar{
            svg{
                path{
                    fill: #FFFFFF;
                }
            }
        }
    }
}

html[data-theme=dark] .innerpage{
    .echo-site-main-logo-menu-social .echo-home-1-social-media-icons{
        .rts-darkmode{
            &::before{
                background-color: #FFFFFF;
            }
            i{
                color: #FFFFFF;
            }
        }
    }
}
html[data-theme=light]{
    .logo-dark{
        display: none;
    }
    .logo-light{
        display: block;
    }
}
html[data-theme=dark]{
    .logo-dark{
        display: block;
    }
    .logo-light{
        display: none;
    }
}
.echo-header-top-menu-bar{
    svg{
        position: relative;
        margin-top: -4px;
    }
}
.home-four{
    .side-bar button{
        background: #FF4962;
    }
    .side-bar .inner-main-wrapper-desk .inner-content .newsletter-form .form-inner form button.subscribe-btn{
        background: #FF4962;
    }
    .side-bar .inner-main-wrapper-desk .inner-content .category-menu-area .category-area .item .content .recent-post-title:hover a{
        color: #FF4962;
    }
}
.home-five{
    .side-bar button{
        background: #D5225B;
    }
    .side-bar .inner-main-wrapper-desk .inner-content .newsletter-form .form-inner form button.subscribe-btn{
        background: #D5225B;
    }
    .side-bar .inner-main-wrapper-desk .inner-content .category-menu-area .category-area .item .content .recent-post-title:hover a{
        color: #D5225B;
    }
}


input{
    transition: 0.3s;
    &:focus{
        border: 1px solid var(--color-primary) !important;
    }
}
textarea{
    transition: 0.3s;
    &:focus{
        border: 1px solid var(--color-primary) !important;
    }
}

.rt-treding-slider10{
    height: 67px;
    line-height: 67px;
}
.img-transition-scales{
    overflow: hidden;
    .img-hovers{
        transform: scale(100%);
        transition: 0.3s ease-in-out;
    }
    &:hover{
        .img-hovers{
            transform: scale(104%);
        }
    }
}
#form-messages{
    &.success{
        color: var(--color-primary);
    }
}
.hm5-btn-1{
    background-color: #D5225B;
    color: var(--color-white);
    font-family:var(--home-5-body-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-transform: uppercase;
    position: relative;
    padding: 25px 55px 23px 35px;
    border-radius: 15px;
    transition: all .4s;
    @media(max-width:768px){
        font-size: 16px;
        padding: 20px 50px 18px 30px;
    }
    &:hover{
        background: #FFFFFF;
        color: #181823;
        .arrow-1{
            opacity: 0;
            transform: translate(10px, -50%);
        }
        .arrow-2{
            opacity: 1;
            transform: translate(0, -50%);
        }
    }
    img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        transition: all .4s;
        &.arrow-2{
            opacity: 0;
            transform: translate(-10px, -50%);
        }
    }
}
.hm5-btn-2{
    background-color: transparent;
    color: #999;
    font-family:var(--home-5-body-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-transform: uppercase;
    position: relative;
    transition: all .4s;
    padding: 18px 50px 16px 25px;
    border-radius: 15px;
    text-align: center;
    border: 1px solid #999999;
    &:hover{
        background: #D5225B;
        border: 1px solid #D5225B;
        color: #FFFFFF;
        .arrow-1{
            opacity: 0;
            transform: translate(10px, -50%);
        }
        .arrow-2{
            opacity: 1;
            transform: translate(0, -50%);
        }
    }
    img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        transition: all .4s;
        &.arrow-2{
            opacity: 0;
            transform: translate(-10px, -50%);
        }
    }
}

.hm-5-title-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hm-5-main-title{
        width: 85%;
        @media(max-width:1460px){
            width: 75%;
        }
        @media(max-width:768px){
            width: auto;
        }
        h2{
            color: var(--color-white);
            font-family: var(--home-5-title-font);
            font-size: 70px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            position: relative;
            @media(max-width: 991px){
                font-size: 56px;
                line-height: 1.1;
            }
            @media(max-width:768px){
                font-size: 50px;
            }
            @media(max-width:576px){
                font-size: 42px;
            }
            &::after{
                position: absolute;
                content: "";
                left: 35%;
                top: 50%;
                transform: translateY(-50%);
                width: 60%;
                height: 1px;
                background-color: #999999;
                @media(max-width: 1460px){
                    width: 55%;
                    left: 50%;
                }
                @media(max-width: 1200px){
                    display: none;
                }
            }
        }
    }
}
.hm-5-container{
    max-width:1706px;
    margin: 0 auto;
    padding: 0 30px;
    @media(max-width:768px){
        padding: 0 15px;
    }
}
.hm-6-container{
    max-width:1600px;
    margin: 0 auto;
    padding: 0 30px;
    @media(max-width:768px){
        padding: 0 15px;
    }
}
.hm-7-container{
    max-width:1300px;
    margin: 0 auto;
    padding: 0 15px;
    @media(max-width:768px){
        padding: 0 15px;
    }
}
.hm-9-container{
    max-width:1720px;
    margin: 0 auto;
    padding: 0 15px;
    @media(max-width:768px){
    }
}
.hm-5-meta-box{
    display: flex;
    align-items: center;
    gap: 12px;
    .hm-5-meta-btn{
        span{
            background-color: #D5225B;
            padding: 7px 20px 5px 20px;
            text-align: center;
            color: var(--color-white);
            text-align: center;
            font-family: var(--home-5-title-font);
            font-size: 16px;
            font-weight: 400;
            border-radius: 5px;
            text-transform: uppercase;
        }
    }
    .hm-5-meta-date{
        span{
            color: #999;
            font-family: var(--home-5-title-font);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
        }
    }
}

.hm7-btn-1{
    color: #FE5301;
    font-family:'Inter', sans-serif;
    border: 1px solid #FE5301;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-transform: uppercase;
    position: relative;
    display: block;
    padding: 15px 55px 13px 35px;
    border-radius: 0;
    transition: all .4s;
    @media(max-width:768px){
        font-size: 16px;
        padding: 20px 50px 18px 30px;
    }
    i{
        color: #FE5301;
    }
    &:hover{
        color: #FE5301;
        .arrow-1{
            opacity: 0;
            transform: translate(10px, -50%);
        }
        .arrow-2{
            opacity: 1;
            transform: translate(0, -50%);
        }
    }
    i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        transition: all .4s;
        &.arrow-2{
            opacity: 0;
            transform: translate(-10px, -50%);
        }
    }
}


// nice select
.ui-state-default{
    text-decoration: none !important;
}
.calendar {
    padding:8px;
    background: #ffffff;
    border-radius: 4px;
    font-size:18px;
    border: 1px solid #333333;
    box-shadow: 0px 20px 40px rgba(59,60,56,0.05);
  }
  .ui-datepicker {
    background: #ffffff;
    border-radius: 0;
    box-shadow: 0px 6px 41px -13px rgba(191,138,0,0.75);
    -webkit-box-shadow: 0px 6px 41px -13px rgba(191,138,0,0.75);
    -moz-box-shadow: 0px 6px 41px -13px rgba(191,138,0,0.75);
  }
  .ui-datepicker-header {
    height: 50px;
    line-height: 50px;
    color: #ffffff;
    background: var(--color-primary);
    margin-bottom: 10px;
  }
  .ui-datepicker-prev,
  .ui-datepicker-next {
    width: 20px;
    height: 20px;
    text-indent: 9999px;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    margin-top: 12px;
  }
  .ui-datepicker-prev {
    float: left;
    margin-left: 12px;
  }
  .ui-datepicker-prev:after {
    transform: rotate(45deg);
    margin: -43px 0px 0px 8px;
  }
  .ui-datepicker-next {
    float: right;
    margin-right: 12px;
  }
  .ui-datepicker-next:after {
    transform: rotate(-135deg);
    margin: -43px 0px 0px 6px;
  }
  .ui-datepicker-prev:after,
  .ui-datepicker-next:after {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
  }
  .ui-datepicker-prev:hover,
  .ui-datepicker-next:hover,
  .ui-datepicker-prev:hover:after,
  .ui-datepicker-next:hover:after {
    border-color: #333333;
  }
  .ui-datepicker-title {
    text-align: center;
    font-size:20px;
  }
  .ui-datepicker-calendar {
    width: 100%;
    text-align: center;
  }
  .ui-datepicker-calendar thead tr th span {
    display: block;
    width: 40px;
    color: var(--color-primary);
    margin-bottom: 5px;
    font-size: 15px;
  }
  .ui-state-default {
    display: block;
    text-decoration: none;
    color: #333333;
    line-height: 40px;
    font-size: 16px;
    line-height: 27px;
    padding: 0 5px;
  }
  .ui-state-default:hover {
    color: #ffffff;
    background:var(--color-primary);
    border-radius:0;
    transition: all 0.25s cubic-bezier(0.7, -0.12, 0.2, 1.12);
    text-decoration: none;
  }
  .ui-state-highlight {
    color: #ffffff;
    background-color:var(--color-primary);
    border-radius: 0;
  }
  .ui-state-active {
    color: #ffffff;
    background-color:var(--color-primary);
    border-radius: 0;
  }
  .ui-datepicker-unselectable .ui-state-default {
    color: #eee;
    border: 2px solid transparent;
  }

  .icon-2 {
      margin-left: -30px;
      margin-top: -26px;
      position: relative;
      color: var(--color-primary);
      font-size:20px;
  }

.sub-title{
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-transform: capitalize;
    margin-bottom: 20px;
    img{
        margin-right: 10px;
    }
}
.heading-title{
    color: var(--color-title);
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-transform: capitalize;
    margin-bottom: 50px;
    @media #{$large-mobile} {
        font-size: 26px;
        line-height: 34px;
    }
    a{
        font-family: var(--title-font);
        background-size: 200% 2px;
    }
}

.video-play-button {
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    span {
        &.outer-text {
            border: none;
            font-family: 'Bona Nova';
            font-size: 20px;
            min-width: max-content;
            margin-left: 75px;
            position: relative;
            margin-top: -12px;
            color: #FFFFFF;
            font-weight: 500;
        }
    }

    &::after {
        content: "";
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        transition: all 200ms;
        border: .5px solid #ffffff;
    }
}

.video-overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: all ease 500ms;
    display: none;
}

.video-overlay-close {
    position: absolute;
    z-index: 1000;
    top: 15px;
    right: 20px;
    font-size: 36px;
    line-height: 1;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: all 200ms;
}

.video-overlay.open {
    position: fixed;
    z-index: 1000;
    opacity: 1;
    display: block;
}

.video-play-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 14px solid var(--color-white);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 47%;
}

.video-overlay {
    iframe {
        width: 70%;
        height: 70%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        top: 50%;
        position: relative;
        transform: translateY(-50%);
    }
}
.rts-btn{
    background: var(--color-primary);
    color: var(--color-white);
    padding: 18px 53px;
    transition: all .3s;
    display: block;
    max-width: max-content;
    line-height: 1;
    height: 50px;
    &:hover{
        background: #00bbdbe0;
        color: var(--color-white);
    }
}
.section-bg2{
    background: #F6F6F6;
}

.cart-bar {
    position: fixed;
    top: 0;
    right: 0;
    background: #fff;
    width: 360px;
    height: 100%;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 100;
  }
  .cart-bar.show {
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .cart-bar .cart-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E7E7E7;
  }
  .cart-bar .cart-header .cart-heading {
    font-size: 17px;
    font-weight: 500;
    color: #404040;
  }
  .cart-bar .product-area3 .product-item .bottom-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cart-bar .product-item {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #E7E7E7;
  }
  .cart-bar .product-item:last-child {
    margin-bottom: 0;
  }
  .cart-bar .product-detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .cart-bar .product-detail span {
    display: inline-block;
    line-height: 19px !important;
  }
  .cart-bar .product-detail .product-thumb {
    margin-right: 15px;
    max-width: 75px;
  }
  .cart-bar .product-detail .product-name {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-title);
  }
  .cart-bar .product-detail .product-variation span {
    color: #868686;
    font-family: roboto;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
  }
  .cart-bar .product-detail .product-qnty {
    margin-right: 5px;
  }
  .cart-bar .product-detail .product-qnty,
  .cart-bar .product-detail .product-price {
    color: #404040;
    font-weight: 500;
    font-size: 13px;
    font-family: roboto;
  }
  .cart-bar .cart-edit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .cart-bar .cart-edit .item-wrapper {
    display: inline-block;
  }
  .cart-bar .cart-edit .quantity-edit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #E7E7E7;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 3px;
  }
  .cart-bar .cart-edit .quantity-edit input {
    text-align: center;
    max-width: 40px;
  }
  .cart-bar .cart-edit .quantity-edit button {
    background: none;
    font-size: 0;
  }
  .cart-bar .cart-edit .quantity-edit button i {
    font-size: 14px;
    color: #C0C0C0;
  }
  .cart-bar .cart-edit .product-edit {
    margin-right: 15px;
  }
  .cart-bar .cart-edit .product-edit i {
    font-size: 13px;
    color: #868686;
  }
  .cart-bar .cart-edit .delete-cart i {
    font-size: 16px;
    color: #4E4E4E;
    margin-top: 5px;
  }
  .cart-bar .cart-bottom-area {
    margin-top: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cart-bar .cart-bottom-area .spend-shipping {
    margin-bottom: 30px;
    background: #F5F5F5;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: 500;
    color: #404040;
    text-align: center;
  }
  .cart-bar .cart-bottom-area .spend-shipping .amount {
    font-weight: 700;
    color: var(--color-title);
  }
  .cart-bar .cart-bottom-area .spend-shipping i {
    font-size: 15px;
    margin-right: 7px;
  }
  .cart-bar .cart-bottom-area .total-price {
    font-size: 18px;
    color: #868686;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .cart-bar .cart-bottom-area .cart-btn {
    width: 100%;
    padding: 10px 20px;
    font-size: 14px;
    text-align: center;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .cart-bar .cart-bottom-area .checkout-btn {
    border: 1px solid var(--color-title);
    margin-bottom: 10px;
  }
  .cart-bar .cart-bottom-area .checkout-btn:hover {
    background: var(--color-primary);
    color: var(--color-white);
    border: 1px solid var(--color-primary);
  }
  .cart-bar .cart-bottom-area .view-btn {
    border: 1px solid transparent;
    background: var(--color-title);
    color: var(--color-white);
  }
  .cart-bar .cart-bottom-area .view-btn:hover {
    background: var(--color-primary);
  }
  @media (max-width: 768px) {
    .cart-bar {
      width: 100%;
    }
  }
  
  .close-cart {
    cursor: pointer;
  }  


.anywere,
.anywere-home {
  background: #0E1013;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  visibility: hidden;
  -webkit-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  pointer-events: none;
  z-index: 99;
}
.anywere.show,
.anywere-home.show {
  visibility: visible;
  pointer-events: visible;
}
.anywere.bgshow,
.anywere-home.bgshow {
    cursor: url(../images/close.png), auto;
    background: #0E1013;
    opacity: 70%;
    visibility: visible;
    pointer-events: visible;
    z-index: 99;
}

.order_md--2{
    @media(max-width:992px){
        order: 2;
    }
}


.loader-wrapper {
    --line-width: 5px;
    --curtain-color: #ffffff;
    --outer-line-color: #dca8a8;
    --middle-line-color: #ff0000;
    --inner-line-color: #cf1414;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:1000;
  }
  
  .loader {
    display:block;
    position: relative;
    top:50%;
    left:50%;
    width:130px;
    height:130px;
    margin:-75px 0 0 -75px;
    // border:var(--line-width) solid transparent;
    // border-top-color: var(--outer-line-color);
    // border-radius:100%;
    // -webkit-animation: spin 2s linear infinite;
    //         animation: spin 2s linear infinite;
    z-index:1001;
  }

.loader:after {
    content:"";
    position: absolute;
    top:14px;
    left:14px;
    right:14px;
    bottom:14px;
    border:5px solid transparent;
    border-top-color: var(--color-primary);
    border-radius:100%;
    -webkit-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0%   { 
      -webkit-transform: rotate(0deg); 
        -ms-transform: rotate(0deg); 
            transform: rotate(0deg);
    }
    100% { 
      -webkit-transform: rotate(360deg); 
        -ms-transform: rotate(360deg); 
            transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0%   { 
      -webkit-transform: rotate(0deg); 
        -ms-transform: rotate(0deg); 
            transform: rotate(0deg);
    }
    100% { 
      -webkit-transform: rotate(360deg); 
        -ms-transform: rotate(360deg); 
            transform: rotate(360deg);
    }
  }
  
  .loader-wrapper .loader-section {
    position:fixed;
    top:0;
    background:var(--color-white);
    width:50%;
    height:100%;
    z-index:1000;
  }
  
  .loader-wrapper .loader-section.section-left {
    left:0
  }
  .loader-wrapper .loader-section.section-right {
    right:0;
  }
  
  /* Loaded Styles */ 
  .loaded .loader-wrapper .loader-section.section-left {
    // transform: translateX(-100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645,0.045,0.355,1.000);
    opacity: 0;
  }
  .loaded .loader-wrapper .loader-section.section-right {
    // transform: translateX(100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645,0.045,0.355,1.000);
    opacity: 0;
  }
  .loaded .loader {
    opacity: 0;
    transition: all 0.3s ease-out;
  }
  .loaded .loader-wrapper {
    visibility: hidden;
    transform:translateY(-100%);
    transition: all .3s 1s ease-out;
  }