// event list area
.even-list-headed{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$md-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }
    .left-head{
        display: flex;
        align-items: center;
        gap: 30px;
    }
    span.day-current{
        display: block;
        padding: 9px 18px;
        max-width: max-content;
        background: #F6F6F6;
    }
    select{
        color: #111;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        text-transform: capitalize;
        max-width: max-content;
        padding: 10px 30px 10px 15px;
        cursor: pointer;
        &:focus{
            box-shadow: none !important;
            border: 1px solid var(--color-primary);
        }
    }
    .right-head{
        @media #{$sm-layout} {
            width: 100%;
        }
        form{
            position: relative;
            input{
                border-bottom: 1px solid #D9D9D9;
                height: 60px;
                width: 600px;
                padding-right: 170px;
                @media #{$sm-layout} {
                    width: 100%;
                }
                &:focus{
                    border: none !important;
                    border-bottom: 1px solid var(--color-primary) !important;
                }
            }
            button{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                padding: 10px 30px;
            }
        }
    }
}


.single-even-list-style{
    margin-top: 70px;
    &:first-child{
        .month-date{
            display: none;
        }
    }
    .month-date{
        color: #111;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-transform: capitalize;
        display: block;
        position: relative;
        padding-top: 1px;
        &::after{
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 1px;
            background: #D9D9D9;
            content: '';
        }
    }
    .inner-content{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 60px;
        gap: 60px;
        @media #{$md-layout} {
            flex-direction: column;
            align-items: flex-start;
        }
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: flex-start;
        }
        .left{
            display: flex;
            align-items: flex-start;
            gap: 30px;
            min-width: max-content;
            @media #{$md-layout} {
                flex-direction: column-reverse;
            }
            @media #{$sm-layout} {
                min-width: 100%;
                flex-direction: column-reverse;
            }
            .date-day{
                min-width: max-content;
                padding: 15px 21px;
                border: 1px solid #D9D9D9;
                text-align: center;
                span{
                    color: #707070;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 40px;
                    text-transform: capitalize;
                    margin-bottom: 0;
                    line-height: 0;
                }
                .year{
                    color: #111;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    text-transform: capitalize;
                    margin-bottom: 0;
                }
            }
            img{
                min-width: max-content;
                display: block;
                @media #{$sm-layout} {
                    min-width: 100%;
                }
            }
        }
        .right{
            .title{
                color: #111;
                transition: .3s;
                &:hover{
                    color: var(--color-primary);
                }
            }
            p.disc{
                margin-bottom: 25px;
            }
            .bottom-info{
                display: flex;
                align-items: center;
                gap: 40px;
                @media #{$large-mobile} {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;
                }
                &>div{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    i{
                        color: var(--color-primary);
                    }
                }
            }
            .bottom-price{
                display: flex;
                align-items: center;
                gap: 50px;
                margin-top: 20px;
                .price{
                    .icon{
                        display: flex;
                        align-items: center;
                        gap: 12px;
                    }
                    h4{
                        color: #111;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 42px;
                        margin-bottom: 0;
                        text-transform: capitalize;
                    }
                }
                a{
                    color: #00bbdb;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                    text-transform: capitalize;
                    i{
                        margin-left: 5px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}


// event details warapper
.event-details-wrapper-1{
    margin-top: 40px;
    .title{
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 108.333% */
        text-transform: capitalize;
    }
    p.disc{
        margin-bottom: 25px;
    }
}

.event-table-wrapper{
    display: block;
    align-items: center;
    margin-top: 0;
    margin-bottom: 30px;
    @media #{$md-layout} {
        flex-wrap: wrap;
    }
    @media #{$sm-layout} {
        flex-wrap: wrap;
    }
    &>div{
        flex-basis: 33.33%;
        border: 1px solid #D9D9D9;
        margin-bottom: 30px;
        background: #F6F6F6;
        @media #{$md-layout} {
            flex-basis: 50%;
        }
        @media #{$sm-layout} {
            flex-basis: 100%;
        }
        &:last-child{
            border: 1px solid #D9D9D9;
        }
        .title{
            padding: 22px 32px;
            border-bottom: 1px solid #D9D9D9;
            margin-bottom: 0;
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            text-transform: capitalize;
        }
        .body{
            padding: 22px 25px;
            .single{
                display: flex;
                align-items: center;
                padding-bottom: 20px;
                &:last-child{
                    padding-bottom: 0;
                }
                &>.name{
                    flex-basis: 32%;
                }
                .name{
                    color: #111;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px;
                    margin-bottom: 0;
                }
                .info{
                    margin-bottom: 0;
                }
            }
        }
    }
}

.next-prev-main-wrapper-event-details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
    padding-top: 30px;
    border-top: 1px solid #D9D9D9;
    .prev-area{
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        p{
            margin: 0;
            transition: .3s;
        }
        i{
            color: .3s;
        }
        &:hover{
            p{
                color: var(--color-primary);
            }
            i{
                color: var(--color-primary);
            }
        }
    }
}